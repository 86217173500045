.HomePageFooterSection {
  display: flex;
  background: #061764;
  justify-content: space-between;
  padding: 200px 150px 50px 150px;
  position: relative;
}
.HomePageFooterSection h4 {
  font-family: Space Grotesk;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #f4e8e8;
  margin: 0px;
  margin-bottom: 50px;
}
.HomePageConnectContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.HomePageConnectContainer a {
  font-family: Space Grotesk;
  font-weight: 300;
  font-size: 32px;
  line-height: 32px;
  color: #f4e8e8;
  cursor: pointer;
  margin: 0px;
  text-decoration: none;
}
.HomePageConnectContainer a:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
}
.HomePageFooterContainer h6 {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #cde5f2;
  margin: 0px;
}
.HomePageFooterContainer p {
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #7289c6;
  margin: 0px;
}

.HomePageGetInTouch {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  background: #0b1542;
  top: -100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 100px;
  border-radius: 10px;
}
.HomePageGetInTouchTextContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.HomePageGetInTouch h3 {
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  color: #cde5f2;
  margin: 0;
}
.HomePageGetInTouch button {
  display: flex;
  align-items: center;
  gap: 16px;
  background: transparent;
  border: 2px solid #13acf3;
  padding: 15px 35px;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;

  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 4px;
  color: #13acf3;
}
.HomePageGetInTouch button:hover {
  background: #13acf3;
  color: #ffffff;
}
.HomePageGetInTouch button:hover img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

@media only screen and (max-width: 68.75rem) {
  .HomePageGetInTouch {
    width: 70%;
  }
}
@media only screen and (max-width: 53.125rem) {
  .HomePageFooterSection {
    padding: 4rem;
    padding-top: 18rem;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .HomePageGetInTouch {
    width: 50%;
    flex-wrap: wrap;
    gap: 2rem;
    top: -8.125rem;
  }
  .HomePageGetInTouchTextContainer {
    width: 100%;
  }
  .HomePageGetInTouchTextContainer h3 {
    font-size: 2.1875rem;
  }
}
@media only screen and (max-width: 26.875rem) {
  .HomePageFooterSection {
    padding-top: 24rem;
    gap: 4rem;
  }
  .HomePageGetInTouch {
    padding: 3.75rem;
  }
}
