.ContactPage {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  background: linear-gradient(225deg, #b2def2 0%, #cde5f2 100%);
  min-height: 100vh;
}
.ContactPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}
.ContactPage h2 {
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.375rem;
  color: #0b1542;
  width: 80%;
}
.ContactPageHeaderName {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  font-family: Space Grotesk;
  color: #0b1542;
}
.HomeButton {
  cursor: pointer;
  padding: 0.8125rem 2.75rem 0.8125rem 2.75rem;
  background-color: transparent;
  border-radius: 0.625rem;
  border: 0.125rem solid #0b1542;

  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  font-family: Roboto;
  color: #0b1542;
}
.HomeButton:hover {
  background: #0b1542;
  color: #ffffff;
}

.ContactPageForm form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.ContactPageInputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.ContactPageInputContainer input {
  max-width: 10rem;
  height: 0.5rem;
}
.ContactPageInputContainer textarea {
  max-width: 40.375rem;
  height: 12.75rem;
  resize: none;
}
.ContactPageInput {
  background: transparent;
  border: 0.0625rem solid #0b1542;
  border-radius: 0.3125rem;
  padding: 1rem;

  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.ContactPageInput:focus-visible {
  outline: 0;
}
.ContactPageForm button {
  background: #13acf3;
  padding: 0.8125rem 2.75rem;
  border-radius: 0.625rem;
  max-width: 10.5rem;
  border: 0;
  margin-top: 2rem;
  cursor: pointer;

  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-align: center;
  color: #e0edf3;
}
.ContactPageForm button:hover {
  background: #0b1542;
}

@media only screen and (max-width: 37.5rem) {
  .ContactPage {
    min-height: 100vh;
    padding-bottom: 2rem;
  }
  .ContactPage h2 {
    width: 100%;
  }
}
@media only screen and (max-width: 25rem) {
  .ContactPage h2 {
    font-size: 2rem;
  }
}
