.HomePageSkillSection {
  padding: 6rem;
  padding-bottom: 0;
  background: linear-gradient(
    225deg,
    #00d4ff 0%,
    #09408b 20%,
    #071862 30%,
    #071862 60%,
    #09408b 75%,
    #00d4ff 100%
  );
}
.HomePagePresentationContainer {
  display: flex;
  align-items: start;
  gap: 2rem;
  padding-bottom: 6rem;
  width: 60%;
}
.HomePagePresentationContainer img {
  margin-top: 0.75rem;
}
.HomePagePresentation {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.HomePagePresentationTitle {
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3rem;
  color: #cde5f2;
}
.HomePagePresentationDescription {
  font-family: Roboto;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #cde5f2;
}
.HomePageSkillContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  padding-left: 3rem;
  padding-bottom: 6rem;
  gap: 1rem;
}
.HomePageSkillTitle {
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3rem;
  color: #cde5f2;
  margin-right: 2rem;
}
.HomePageSkills {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 3rem;
  column-gap: 5rem;
}
.HomePageSkillCard {
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  border: 0.125rem solid #cde5f2;
  padding: 40px 100px 60px 40px;
  gap: 30px;
}
.HomePageSkillCard img {
  width: 5.75rem;
  height: 5.75rem;
}
.HomePageSkillCardTitle {
  font-family: Space Grotesk;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 2.875rem;
  color: #cde5f2;
}
.HomePageSkillCardContent {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 0.5rem;
  column-gap: 3.75rem;
  grid-auto-flow: column;
}
.HomePageSkillCardContent p {
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  white-space: nowrap;
  margin: 0rem;
  color: #cde5f2;
}

@media only screen and (max-width: 68.75rem) {
  .HomePageSkillContainer {
    flex-direction: column;
  }
  .HomePageSkills {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 46.875rem) {
  .HomePageSkillSection {
    padding: 2rem;
    padding-top: 4rem;
  }
  .HomePagePresentationContainer {
    width: 100%;
  }
}
@media only screen and (max-width: 32.5rem) {
  .HomePageSkillContainer {
    padding-left: 0rem;
    align-items: center;
  }
  .HomePageSkillCardContent {
    grid-auto-flow: row;
    grid-template-rows: auto;
  }
  .HomePageSkillCard {
    padding: 2.5rem;
  }
  .HomePageSkills {
    width: 100%;
  }
}
