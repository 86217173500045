.HomePageIntroSection {
  padding: 4rem;
  padding-top: 2rem;
  padding-bottom: 0;
  background: linear-gradient(
    135deg,
    #000000 0%,
    #0c1540 25%,
    #071862 50%,
    #09408b 75%,
    #00d4ff 100%
  );
}

.HomePageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}
.HomePageHeaderName {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  font-family: Space Grotesk;
  color: #f4e8e8;
}
.HomePageHeaderButtons {
  display: flex;
  align-items: center;
  gap: 4rem;
}
.ResumeButton {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;

  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  font-family: Roboto;
  color: #ffffff;
  text-decoration: none;
}
.ResumeButton img {
  width: 1.5rem;
  height: 1.5rem;
  filter: brightness(0) invert(1);
}
.ResumeButton:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.SayHelloButton {
  cursor: pointer;
  padding: 0.8125rem 2.75rem 0.8125rem 2.75rem;
  background-color: transparent;
  border-radius: 0.625rem;
  border: 0.125rem solid #13acf3;

  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  font-family: Roboto;
  color: #13acf3;
}
.SayHelloButton:hover {
  background: #13acf3;
  color: #ffffff;
}

.HomePageIntroduction {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
.HomePageIntroductionLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.HomePageIntroductionTitle {
  font-family: Space Grotesk;
  font-weight: 700;
  font-size: 3.875rem;
  line-height: 4.5rem;
  color: #e0edf3;
}
.HomePageIntroductionLineBreak {
  width: 5.505rem;
  height: 0.375rem;
  border-radius: 0.625rem;
  background-color: #13acf3;
}
.HomePageIntroductionDescription {
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #e0edf3;
}
.HomePageIntroductionSocialContainer {
  display: flex;
  gap: 5rem;
  margin-left: 1rem;
  margin-top: 3rem;
}
.HomePageIntroductionSocialContainer img {
  cursor: pointer;
}
.HomePageIntroductionSocialContainer img:hover {
  filter: invert(42%) sepia(99%) saturate(1611%) hue-rotate(163deg)
    brightness(97%) contrast(101%);
}
.HomePageBackgroundImage {
  width: 42.75rem;
  height: 52.5625rem;
}
.HomePageLineBreak {
  width: 100%;
  height: 1px;
  margin-top: 5rem;
  background: #d9d9d9;
}

@media only screen and (max-width: 75rem) {
  .HomePageIntroductionLeftSide {
    width: 60%;
  }
  .HomePageIntroductionRightSide {
    width: 40%;
  }
}
@media only screen and (max-width: 46.875rem) {
  .HomePageHeader {
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  .HomePageIntroductionTitle {
    font-size: 50px;
  }
  .HomePageIntroductionLeftSide {
    width: 100%;
  }
  .HomePageIntroductionRightSide {
    display: none;
  }
}
@media only screen and (max-width: 28.75rem) {
  .SayHelloButton {
    padding: 0.75rem;
    white-space: nowrap;
  }
  .HomePageIntroductionTitle {
    font-size: 2.5rem;
  }
}
